import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import styles from './Styles/SearchBarCss'
import CircularProgress from '@material-ui/core/CircularProgress'
import { addressActions } from '../../../actions'
import { companiesActions } from '../../../actions/companies/companies.actions'
import { InputAdornment } from '@material-ui/core'
class SearchBar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            search: null,
            focused: false,
        }

        this.handleSearch = this.handleSearch.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(value) {
        this.props.onValueChange({
            lastSearch: this.props.searchType,
            lastValue: value,
        })
        if (this.props.searchType === 'company') {
            this.setState({ search: value })
            if (value.length > 1) {
                this.props.dispatch(
                    companiesActions.getSirenByCompanyName(value),
                )
            }
        }
    }

    handleSearch(address) {
        this.setState({ search: null })
        this.props.onValueChange({ lastSearch: null, lastValue: '' })
        this.props.onSearch(address)
    }

    handleCompany(company) {
        this.setState({ search: null })
        this.props.onSearch(company)
    }

    renderAutoCompletion() {
        const { classes, searchType } = this.props
        if (searchType === 'company') {
            if (
                this.props.companyInfo &&
                this.props.companyInfo.length > 0 &&
                this.state.search
            ) {
                return (
                    <ul
                        className={classes.autoCompletion}
                        style={{ listStyle: 'none' }}
                    >
                        {this.props.companyInfo.map((company, index) => (
                            <li
                                className={classes.field}
                                key={index}
                                onClick={() => {
                                    this.handleCompany(company)
                                }}
                            >
                                {`${company.companyName} ${
                                    company.postalCode
                                        ? `(${company.postalCode})`
                                        : ''
                                }`}
                            </li>
                        ))}
                    </ul>
                )
            }
        }

        return <div />
    }

    render() {
        const {
            classes,
            className,
            notOutlined,
            addressValue,
            textFieldClass,
            placeholder,
            margin,
            iconStart,
            nullable,
        } = this.props

        return (
            <div className={className ? className : classes.root}>
                <div className={classes.search}>
                    <TextField
                        InputLabelProps={{
                            shrink: addressValue !== '',
                        }}
                        id="searchBar"
                        margin={margin || 'dense'}
                        required
                        variant={notOutlined ? 'standard' : 'outlined'}
                        label={this.props.label}
                        value={nullable ? addressValue : addressValue ?? ''}
                        placeholder={placeholder}
                        onChange={e => {
                            this.handleChange(e.target.value)
                        }}
                        autoComplete="off"
                        className={
                            textFieldClass ? textFieldClass : classes.bar
                        }
                        onFocus={() => this.setState({ focused: true })}
                        onBlur={() =>
                            setTimeout(
                                () => this.setState({ focused: false }),
                                100,
                            )
                        }
                        InputProps={{
                            endAdornment: (
                                <React.Fragment>
                                    {!this.props.autoCompletion &&
                                    !this.props.companyInfo &&
                                    addressValue ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                </React.Fragment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    {iconStart}
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                {this.state.focused && this.renderAutoCompletion()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        autoCompletion: state.address.autoComplet,
        companyInfo: state.companies.companyInfo,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(SearchBar))
