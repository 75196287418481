import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Book from '@material-ui/icons/Book'
import AttachFile from '@material-ui/icons/AttachFile'
import BoatOnComponent from '../common/BoatOnComponent'
import HomeIcon from '@material-ui/icons/Home'
import SettingsIcon from '@material-ui/icons/Settings'
import SideNavigation from '../common/SideNavigation'
import styles from './Styles/LogbookNavigationCss'
import boaticon from '../../images/boaticon.png'
import logbookIcon from '../../images/Logbook_icon.svg'
import InventoryPage from './LogbookNavigationPage/Inventory/InventoryPage'
import MyFleetPage from './LogbookNavigationPage/MyFleet/MyFleetPage'
import RepairPage from './LogbookNavigationPage/RepairPage/RepairPage'
import BoatOnBookPage from './LogbookNavigationPage/BoatOnBookPage/BoatOnBookPage'
import BoatInfoPage from './LogbookNavigationPage/BoatInfoPage/BoatInfoPage'
import ExpIncPage from './LogbookNavigationPage/ExpIncPage/ExpIncPage'
import MyDocumentPage from './LogbookNavigationPage/MyDocumentPage/MyDocumentPage'
import creditcard from '../../images/creditcard.png'
import repairer from '../../images/repairer.png'

import { userActions } from '../../actions'
import AppRoute from '../../constants/AppRoute'
import { appStrings } from '../../languages'
import { LocalizedLink } from '../../languages'
import dictionary from './Dictionary/LogBookNavigationDico'
import { typesActions } from '../../actions/types.actions'
import OrderForm from './LogbookNavigationPage/Inventory/OrderForm/OrderForm'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Tooltip,
} from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment'
import MyUsersPage from './LogbookNavigationPage/MyUsersPage/MyUsersPage'
import { history } from '../../helpers'
import BoatOnBlock from '../common/Blocks/BoatOnBlock'
import { boatActions } from '../../actions/boat.actions'
import { CalendarMonth } from '@mui/icons-material'
import TimelapseIcon from '@mui/icons-material/Timelapse'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import CalendarPage from './LogbookNavigationPage/CalendarPage/CalendarPage'
import WorkTimePage from './LogbookNavigationPage/WorkTimePage/WorkTimePage'
import WorkTimeSettingPage from './LogbookNavigationPage/WorkTimeSettingPage/WorkTimeSettingPage'
import PaidVacationsSettingsPage from './LogbookNavigationPage/PaidVacationsSettingsPage/PaidVacationsSettingsPage'
import LogBookPage from './LogbookNavigationPage/LogBookPage/LogBookPage'
import NavigationPage from './LogbookNavigationPage/LogBookPage/NavigationPage/NavigationPage'
import OrderPage from './LogbookNavigationPage/OrderPage/OrderPage'
import {
    Actions,
    PermissionsEntities,
    PermissionsPageTypeIds,
} from '../../constants'

const ToolTipCTA = React.forwardRef(function ToolTipLink(props, ref) {
    const HtmlTooltip = withStyles(theme => ({
        tooltip: {
            backgroundColor: '#ffffff',
            color: 'black',
            maxWidth: 330,
            marginTop: -8,
            border: '1px solid #dadde9',
            fontSize: '12px',
            '& span': {
                '&:hover': {
                    cursor: `pointer`,
                },
                fontWeight: `bold`,
                textDecoration: `underline`,
            },
        },
    }))(Tooltip)

    return (
        <HtmlTooltip
            interactive
            ref={ref}
            title={
                props.softMsg ? (
                    props.title
                ) : (
                    <p>
                        {' '}
                        {props.title}{' '}
                        <span onClick={props.redirect}> {props.link} </span>{' '}
                    </p>
                )
            }
        >
            <div>
                <LocalizedLink {...props} />
            </div>
        </HtmlTooltip>
    )
})

const DICT_PAGES_IDS = [
    { name: 'Book', id: 2 },
    { name: 'Documents', id: -1 },
    { name: 'Repair', id: 4 },
    { name: 'ExpensesAndIncome', id: 5 },
    { name: 'Inventory', id: 6 },
    { name: 'MyUsers', id: 7 },
    { name: 'Fleet', id: 8 },
    { name: 'Calendar', id: 11 },
    { name: 'WorkTime', id: -1 },
    { name: 'PaidVacations', id: 7 },
    { name: 'WorkTimes', id: 7 },
    { name: 'Home', id: -1 }, //accès libre
    { name: 'Informations', id: -1 }, //accès libre
    { name: 'Pricing', id: -1 }, //accès libre
    { name: 'OrderForm', id: -1 }, //accès libre
    { name: 'Orders', id: -1 }, //accès libre
    { name: 'LogBook', id: 15 },
    { name: 'LogBookID', id: 15 },
]

const NEW_CONTENT = [
    // "Fleet",
    // "Boaton Book",
    // "Calendar",
    'Worktime',
    // "Inventory",
    // "Repair",
    // "ExpensesAndIncome",
    // "Documents",
    // "Boat",
    // "MyUsers",
    'WorkTimes',
    'Orders',
    'PaidVacations',
    'LogBook',
]

class LogbookNavigation extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            openPopOver: false,
            anchorEl: false,
            open: false,
        }

        this.handleOpenPopOver = this.handleOpenPopOver.bind(this)
        this.handleClosePopOver = this.handleClosePopOver.bind(this)
        this.redirect = this.redirect.bind(this)
        this.redirectPro = this.redirectPro.bind(this)
        this.redirectPortal = this.redirectPortal.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.changePage = this.changePage.bind(this)
        this.shouldRenderBoatSelecter = this.shouldRenderBoatSelecter.bind(this)
        this.getCurrentPageName = this.getCurrentPageName.bind(this)
        this.getCurrentPageInfos = this.getCurrentPageInfos.bind(this)
    }

    componentDidMount() {
        const { eventTypes } = this.props
        if (!eventTypes) this.props.dispatch(typesActions.requestEventTypes())
        if (
            this.props.currentGroupId &&
            !this.props.numberBoats &&
            this.props.boatsLoading === 0
        )
            this.props.dispatch(
                boatActions.getFleetNumberBoat(this.props.currentGroupId),
            )
    }

    componentDidUpdate() {
        const {
            groupLoading,
            boatsLoading,
            loadingUser,
            redirectEvent,
            redirectParams,
            currentGroupId,
            subscriptions,
            numberBoats,
        } = this.props
        if (
            this.props.currentGroupId &&
            !this.props.numberBoats &&
            this.props.boatsLoading === 0
        )
            this.props.dispatch(
                boatActions.getFleetNumberBoat(this.props.currentGroupId),
            )

        if (
            redirectEvent &&
            redirectParams &&
            groupLoading === 0 &&
            boatsLoading === 0 &&
            loadingUser === 0
        ) {
            if (
                this.props.pathname !==
                this.translater.getRoute(
                    this.context,
                    redirectParams.redirectRoute,
                )
            ) {
                return this.historyPush(history, redirectParams.redirectRoute)
            }
        }

        if (currentGroupId && !numberBoats && boatsLoading === 0)
            this.props.dispatch(boatActions.getFleetNumberBoat(currentGroupId))

        const currentPageInfos = this.getCurrentPageInfos()

        if (
            subscriptions &&
            groupLoading === 0 &&
            currentPageInfos &&
            currentPageInfos.id !== -1 &&
            !userActions.checkSubscriptionPage(
                subscriptions,
                currentPageInfos.id,
            )
        ) {
            subscriptions.length === 0
                ? this.redirect()
                : this.historyPush(history, AppRoute.LogBook.Repair)
        }
    }

    handleClick() {
        this.setState({ open: !this.state.open })
    }

    openMenu() {
        if (!this.state.open) {
            this.setState({
                open: true,
            })
        }
    }

    redirect() {
        this.historyPush(history, AppRoute.LogBook.Pricing)
    }

    redirectPro() {
        this.historyPush(history, AppRoute.PricesPage, '', {
            state: {
                particulier: false,
            },
        })
    }

    isSamePath(currentPath, appPath) {
        const logBookPath = this.getLogBookPath(appPath)
        const regex = new RegExp(
            `^${logBookPath.replace(/(:.*?)(\/|$)/gm, '.+')}(\/?)$`,
        )

        return regex.test(currentPath)
    }

    getMain(subs) {
        let { pathname } = this.props
        if (subs === null) subs = []

        if (
            !userActions.checkSubscriptionPage(subs, 2) &&
            this.isSamePath(pathname, AppRoute.LogBook.Home)
        ) {
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Read,
                        entityName: PermissionsEntities.InventoryDetail,
                    }}
                    page
                    children={<InventoryPage />}
                />
            )
        }

        let max = -1
        let subscriptions = null
        for (let sub of subs) {
            if (sub.subType.id > max) {
                max = sub.subType.id
                subscriptions = sub
            }
        }

        if (
            this.isSamePath(pathname, AppRoute.LogBook.Fleet) &&
            subscriptions?.subType?.rightsPage &&
            (subscriptions.subType.rightsPage.find(e => e.pageType.id === 8) !==
                undefined ||
                this.props.currentGroupId > 0)
        ) {
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Read,
                        pageTypeId: PermissionsPageTypeIds.Fleet,
                    }}
                    page
                    children={<MyFleetPage />}
                />
            )
        }
        /*if (pathname === this.getLogBookPath(AppRoute.LogBook.Alert)) {
            return <AlertPage />
        } TODO: to remove */
        if (this.isSamePath(pathname, AppRoute.LogBook.Inventory))
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Read,
                        entityName: PermissionsEntities.InventoryDetail,
                    }}
                    page
                    children={<InventoryPage />}
                />
            )

        if (this.isSamePath(pathname, AppRoute.LogBook.Settings.MyUsers))
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Read,
                        entityName: PermissionsEntities.LinkRoleGroupUser,
                    }}
                    page
                    children={<MyUsersPage />}
                />
            )

        if (this.isSamePath(pathname, AppRoute.LogBook.Repair))
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Read,
                        entityName: PermissionsEntities.Checkup,
                    }}
                    page
                    children={<RepairPage />}
                />
            )

        if (this.isSamePath(pathname, AppRoute.LogBook.ExpensesAndIncome))
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Read,
                        entityName: PermissionsEntities.Transaction,
                    }}
                    page
                    children={<ExpIncPage />}
                />
            )

        if (this.isSamePath(pathname, AppRoute.LogBook.Documents))
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Read,
                        entityName: PermissionsEntities.File,
                    }}
                    page
                    children={<MyDocumentPage />}
                />
            )

        if (this.isSamePath(pathname, AppRoute.LogBook.Informations))
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Read,
                        entityName: PermissionsEntities.Boat,
                    }}
                    page
                    children={<BoatInfoPage />}
                />
            )

        if (this.isSamePath(pathname, AppRoute.LogBook.OrderForm))
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Create,
                        entityName: PermissionsEntities.Order,
                    }}
                    page
                    children={<OrderForm />}
                />
            )

        if (this.isSamePath(pathname, AppRoute.LogBook.Orders))
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Read,
                        entityName: PermissionsEntities.Order,
                    }}
                    page
                    children={<OrderPage />}
                />
            )

        if (this.isSamePath(pathname, AppRoute.LogBook.Calendar))
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Read,
                        pageTypeId: PermissionsPageTypeIds.Calendar,
                    }}
                    page
                    children={<CalendarPage />}
                />
            )

        if (this.isSamePath(pathname, AppRoute.LogBook.WorkTime))
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Read,
                        entityName: PermissionsEntities.WorkTime,
                    }}
                    page
                    children={<WorkTimePage />}
                />
            )

        if (this.isSamePath(pathname, AppRoute.LogBook.Settings.PaidVacations))
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Read,
                        entityName: PermissionsEntities.PaidVacationSetting,
                    }}
                    page
                    children={<PaidVacationsSettingsPage />}
                />
            )

        if (this.isSamePath(pathname, AppRoute.LogBook.LogBook))
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Read,
                        entityName: PermissionsEntities.Navigation,
                    }}
                    page
                    children={<LogBookPage />}
                />
            )

        if (this.isSamePath(pathname, AppRoute.LogBook.LogBookID))
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Read,
                        entityName: PermissionsEntities.Navigation,
                    }}
                    page
                    children={<NavigationPage />}
                />
            )

        if (this.isSamePath(pathname, AppRoute.LogBook.Settings.WorkTimes))
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Read,
                        entityName: PermissionsEntities.WorkTimeSetting,
                    }}
                    page
                    children={<WorkTimeSettingPage />}
                />
            )

        if (pathname === '/my-boat-logbook/boaton-book') {
            return <BoatOnBookPage />
        }
        return (
            <BoatOnBlock
                permissions={{
                    action: Actions.Read,
                    pageTypeId: PermissionsPageTypeIds.BoatOnBookChrono,
                }}
                page
                children={<BoatOnBookPage />}
            />
        )
    }

    getLogBookPath(route) {
        let context = this.getContext()
        return `/${context}` + appStrings[context][route]
    }

    handleOpenPopOver(e) {
        if (this.state.openPopOver) return
        this.setState({
            openPopOver: true,
            anchorEl: e.currentTarget,
        })
    }

    handleClosePopOver() {
        if (!this.state.openPopOver) return
        this.setState({
            openPopOver: false,
            anchorEl: null,
        })
    }

    changePage() {
        if (window.innerWidth < 600) {
            this.setState({ open: false })
        }
    }

    async redirectPortal() {
        const url = await userActions.showClientPortail()
        window.location.assign(url)
    }

    menu(subs) {
        const { classes } = this.props
        if (subs === null) subs = []

        const redirectPro =
            subs?.length > 0 ? this.redirectPortal : this.redirectPro
        const redirect = subs?.length > 0 ? this.redirectPortal : this.redirect

        const newBadge = <span className={classes.newBadge}>New</span>

        return (
            <div>
                <List className={classes.nav} component="nav">
                    {/* Fleet */}
                    <BoatOnBlock
                        permissions={{
                            action: Actions.Read,
                            pageTypeId: PermissionsPageTypeIds.Fleet,
                        }}
                        children={
                            <>
                                <ListItem
                                    button
                                    cta={userActions.checkSubscriptionPage(
                                        subs,
                                        8,
                                    )}
                                    component={
                                        userActions.checkSubscriptionPage(
                                            subs,
                                            8,
                                        )
                                            ? LocalizedLink
                                            : ToolTipCTA
                                    }
                                    to={AppRoute.LogBook.Fleet}
                                    disabled={
                                        !userActions.checkSubscriptionPage(
                                            subs,
                                            8,
                                        )
                                    }
                                    title={
                                        !userActions.checkSubscriptionPage(
                                            subs,
                                            8,
                                        )
                                            ? this.displayText(`bosco`)
                                            : null
                                    }
                                    link={this.displayText(`cta`)}
                                    redirect={redirect}
                                >
                                    <ListItemIcon
                                        title={this.displayText('fleet')}
                                        className={classes.icon}
                                        onClick={this.changePage}
                                    >
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        title={this.displayText('fleet')}
                                        primary={this.displayText('fleet')}
                                        secondary={
                                            NEW_CONTENT.includes('Fleet') &&
                                            newBadge
                                        }
                                        disableTypography
                                        onClick={this.changePage}
                                    />
                                </ListItem>
                                <Divider />
                            </>
                        }
                    />

                    {/* Logbook */}
                    <BoatOnBlock
                        permissions={{
                            action: Actions.Read,
                            entityName: PermissionsEntities.Navigation,
                        }}
                        children={
                            <>
                                <ListItem
                                    button
                                    to={AppRoute.LogBook.LogBook}
                                    title={this.displayText(`cta`)}
                                    link={
                                        userActions.checkSubscriptionPage(
                                            subs,
                                            15,
                                        ) === 'sub_V1'
                                            ? this.displayText(`subscribeV2`)
                                            : this.displayText(`skipper`)
                                    }
                                    component={
                                        userActions.checkSubscriptionPage(
                                            subs,
                                            15,
                                        ) &&
                                        userActions.checkSubscriptionPage(
                                            subs,
                                            15,
                                        ) !== 'sub_V1'
                                            ? LocalizedLink
                                            : ToolTipCTA
                                    }
                                    disabled={
                                        !userActions.checkSubscriptionPage(
                                            subs,
                                            15,
                                        ) ||
                                        userActions.checkSubscriptionPage(
                                            subs,
                                            15,
                                        ) === 'sub_V1'
                                    }
                                    redirect={redirect}
                                >
                                    <ListItemIcon
                                        title={this.displayText('logBook')}
                                        className={classes.icon}
                                        onClick={this.changePage}
                                    >
                                        <img
                                            src={logbookIcon}
                                            alt={this.displayText('logBook')}
                                            title={this.displayText('logBook')}
                                            className={classes.iconMenu}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        title={this.displayText('logBook')}
                                        primary={this.displayText('logBook')}
                                        secondary={
                                            NEW_CONTENT.includes('LogBook') &&
                                            newBadge
                                        }
                                        disableTypography
                                        onClick={this.changePage}
                                    />
                                </ListItem>
                                <Divider />
                            </>
                        }
                    />

                    {/* Boaton Book */}
                    <BoatOnBlock
                        permissions={{
                            action: Actions.Read,
                            pageTypeId: PermissionsPageTypeIds.BoatOnBookChrono,
                        }}
                        children={
                            <>
                                <ListItem
                                    button
                                    title={this.displayText(`cta`)}
                                    link={this.displayText(`skipper`)}
                                    redirect={redirect}
                                    component={
                                        userActions.checkSubscriptionPage(
                                            subs,
                                            2,
                                        )
                                            ? LocalizedLink
                                            : ToolTipCTA
                                    }
                                    to={AppRoute.LogBook.Book}
                                    disabled={
                                        !userActions.checkSubscriptionPage(
                                            subs,
                                            2,
                                        )
                                    }
                                >
                                    <ListItemIcon
                                        className={classes.icon}
                                        title="BoatOn Book"
                                        onClick={this.changePage}
                                    >
                                        <Book />
                                    </ListItemIcon>
                                    <ListItemText
                                        title="BoatOn Book"
                                        primary="BoatOn Book"
                                        secondary={
                                            NEW_CONTENT.includes(
                                                'Boaton Book',
                                            ) && newBadge
                                        }
                                        disableTypography
                                        onClick={this.changePage}
                                    />
                                </ListItem>
                                <Divider />
                            </>
                        }
                    />

                    {/* Calendar */}
                    <BoatOnBlock
                        permissions={{
                            action: Actions.Read,
                            pageTypeId: PermissionsPageTypeIds.Calendar,
                        }}
                        children={
                            <>
                                <ListItem
                                    button
                                    title={this.displayText(`cta`)}
                                    link={this.displayText(`captain`)}
                                    redirect={redirect}
                                    component={
                                        userActions.checkSubscriptionPage(
                                            subs,
                                            11,
                                        )
                                            ? LocalizedLink
                                            : ToolTipCTA
                                    }
                                    to={AppRoute.LogBook.Calendar}
                                    disabled={
                                        !userActions.checkSubscriptionPage(
                                            subs,
                                            11,
                                        )
                                    }
                                >
                                    <ListItemIcon
                                        className={classes.icon}
                                        title={this.displayText('calendar')}
                                        onClick={this.changePage}
                                    >
                                        <CalendarMonth />
                                    </ListItemIcon>
                                    <ListItemText
                                        title={this.displayText('calendar')}
                                        primary={this.displayText('calendar')}
                                        secondary={
                                            NEW_CONTENT.includes('Calendar') &&
                                            newBadge
                                        }
                                        disableTypography
                                        onClick={this.changePage}
                                    />
                                </ListItem>
                                <Divider />
                            </>
                        }
                    />

                    {/* Worktime */}
                    <BoatOnBlock
                        permissions={{
                            action: Actions.Read,
                            entityName: PermissionsEntities.WorkTime,
                        }}
                        children={
                            <>
                                <ListItem
                                    button
                                    title={this.displayText(`cta`)}
                                    link={this.displayText(`amiral`)}
                                    redirect={redirect}
                                    component={
                                        userActions.checkSubscriptionPage(
                                            subs,
                                            12,
                                        )
                                            ? LocalizedLink
                                            : ToolTipCTA
                                    }
                                    to={AppRoute.LogBook.WorkTime}
                                    disabled={
                                        !userActions.checkSubscriptionPage(
                                            subs,
                                            12,
                                        )
                                    }
                                >
                                    <ListItemIcon
                                        className={classes.icon}
                                        title={this.displayText('workTime')}
                                        onClick={this.changePage}
                                    >
                                        <TimelapseIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        title={this.displayText('workTime')}
                                        primary={this.displayText('workTime')}
                                        secondary={
                                            NEW_CONTENT.includes('Worktime') &&
                                            newBadge
                                        }
                                        disableTypography
                                        onClick={this.changePage}
                                    />
                                </ListItem>
                                <Divider />
                            </>
                        }
                    />
                    {/* Inventory */}
                    <BoatOnBlock
                        permissions={{
                            action: Actions.Read,
                            entityName: PermissionsEntities.InventoryDetail,
                        }}
                        children={
                            <>
                                <ListItem
                                    button
                                    component={LocalizedLink}
                                    to={AppRoute.LogBook.Inventory}
                                >
                                    <ListItemIcon
                                        title={this.displayText('inventory')}
                                        className={classes.icon}
                                        onClick={this.changePage}
                                    >
                                        <AssignmentIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        title={this.displayText('inventory')}
                                        primary={this.displayText('inventory')}
                                        secondary={
                                            NEW_CONTENT.includes('Inventory') &&
                                            newBadge
                                        }
                                        disableTypography
                                        onClick={this.changePage}
                                    />
                                </ListItem>
                                <Divider />
                            </>
                        }
                    />
                    {/* Orders */}
                    <BoatOnBlock
                        permissions={{
                            action: Actions.Read,
                            entityName: PermissionsEntities.Order,
                        }}
                        children={
                            <>
                                <ListItem
                                    button
                                    title={this.displayText(`cta`)}
                                    link={this.displayText(`commander`)}
                                    redirect={redirect}
                                    component={
                                        userActions.checkSubscriptionPage(
                                            subs,
                                            16,
                                        )
                                            ? LocalizedLink
                                            : ToolTipCTA
                                    }
                                    to={AppRoute.LogBook.Orders}
                                    disabled={
                                        !userActions.checkSubscriptionPage(
                                            subs,
                                            16,
                                        )
                                    }
                                >
                                    <ListItemIcon
                                        className={classes.icon}
                                        title={this.displayText('orders')}
                                        onClick={this.changePage}
                                    >
                                        <RequestQuoteIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        title={this.displayText('orders')}
                                        primary={this.displayText('orders')}
                                        secondary={
                                            NEW_CONTENT.includes('Orders') &&
                                            newBadge
                                        }
                                        disableTypography
                                        onClick={this.changePage}
                                    />
                                </ListItem>
                                <Divider />
                            </>
                        }
                    />
                    {/* Repair */}
                    <BoatOnBlock
                        permissions={{
                            action: Actions.Read,
                            entityName: PermissionsEntities.Checkup,
                        }}
                        children={
                            <>
                                <ListItem
                                    button
                                    title={this.displayText(`cta`)}
                                    link={this.displayText(`skipper`)}
                                    redirect={redirect}
                                    component={
                                        userActions.checkSubscriptionPage(
                                            subs,
                                            4,
                                        )
                                            ? LocalizedLink
                                            : ToolTipCTA
                                    }
                                    to={AppRoute.LogBook.Repair}
                                    disabled={
                                        !userActions.checkSubscriptionPage(
                                            subs,
                                            4,
                                        )
                                    }
                                >
                                    <ListItemIcon
                                        title={this.displayText('checkup')}
                                        className={classes.icon}
                                        onClick={this.changePage}
                                    >
                                        <img
                                            src={repairer}
                                            alt={this.displayText('checkup')}
                                            title={this.displayText('checkup')}
                                            className={classes.iconRepairMenu}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        title={this.displayText('checkup')}
                                        primary={this.displayText('checkup')}
                                        secondary={
                                            NEW_CONTENT.includes('Repair') &&
                                            newBadge
                                        }
                                        disableTypography
                                        onClick={this.changePage}
                                    />
                                </ListItem>
                                <Divider />
                            </>
                        }
                    />

                    {/* ExpensesAndIncome */}
                    <BoatOnBlock
                        permissions={{
                            action: Actions.Read,
                            entityName: PermissionsEntities.Transaction,
                        }}
                        children={
                            <>
                                <ListItem
                                    button
                                    component={
                                        userActions.checkSubscriptionPage(
                                            subs,
                                            5,
                                        )
                                            ? LocalizedLink
                                            : ToolTipCTA
                                    }
                                    title={this.displayText(`cta`)}
                                    link={this.displayText(`skipper`)}
                                    redirect={redirect}
                                    to={AppRoute.LogBook.ExpensesAndIncome}
                                    disabled={
                                        !userActions.checkSubscriptionPage(
                                            subs,
                                            5,
                                        )
                                    }
                                >
                                    <ListItemIcon
                                        title={this.displayText('expense')}
                                        className={classes.icon}
                                        onClick={this.changePage}
                                    >
                                        <img
                                            src={creditcard}
                                            alt={this.displayText('expense')}
                                            title={this.displayText('expense')}
                                            className={classes.iconMenu}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        title={this.displayText('expense')}
                                        primary={this.displayText('expense')}
                                        secondary={
                                            NEW_CONTENT.includes(
                                                'ExpensesAndIncome',
                                            ) && newBadge
                                        }
                                        disableTypography
                                        onClick={this.changePage}
                                    />
                                </ListItem>
                                <Divider />
                            </>
                        }
                    />

                    {/* Documents */}
                    <BoatOnBlock
                        permissions={{
                            action: Actions.Read,
                            entityName: PermissionsEntities.File,
                        }}
                        children={
                            <>
                                <ListItem
                                    button
                                    component={LocalizedLink}
                                    to={AppRoute.LogBook.Documents}
                                >
                                    <ListItemIcon
                                        title={this.displayText('documents')}
                                        className={classes.icon}
                                        onClick={this.changePage}
                                    >
                                        <AttachFile />
                                    </ListItemIcon>
                                    <ListItemText
                                        title={this.displayText('documents')}
                                        primary={this.displayText('documents')}
                                        secondary={
                                            NEW_CONTENT.includes('Documents') &&
                                            newBadge
                                        }
                                        disableTypography
                                        onClick={this.changePage}
                                    />
                                </ListItem>
                                <Divider />
                            </>
                        }
                    />

                    {/* Boat */}
                    <BoatOnBlock
                        permissions={{
                            action: Actions.Read,
                            entityName: PermissionsEntities.Boat,
                        }}
                        children={
                            <>
                                <ListItem
                                    button
                                    component={LocalizedLink}
                                    to={AppRoute.LogBook.Informations}
                                >
                                    <ListItemIcon
                                        title={this.displayText('boat')}
                                        className={classes.icon}
                                        onClick={this.changePage}
                                    >
                                        <img
                                            src={boaticon}
                                            alt={this.displayText('boat')}
                                            title={this.displayText('boat')}
                                            className={classes.iconMenu}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        title={this.displayText('boat')}
                                        primary={this.displayText('boat')}
                                        secondary={
                                            NEW_CONTENT.includes('Boat') &&
                                            newBadge
                                        }
                                        disableTypography
                                        onClick={this.changePage}
                                    />
                                </ListItem>
                                <Divider />
                            </>
                        }
                    />

                    {
                        <BoatOnBlock
                            permissions={[
                                {
                                    action: Actions.Read,
                                    entityName:
                                        PermissionsEntities.LinkRoleGroupUser,
                                },
                                {
                                    action: Actions.Read,
                                    entityName:
                                        PermissionsEntities.PaidVacationSetting,
                                },
                                {
                                    action: Actions.Read,
                                    entityName:
                                        PermissionsEntities.WorkTimeSetting,
                                },
                            ]}
                            onlyOnePermission
                            children={
                                <Accordion
                                    classes={{
                                        root: classes.accordionRoot,
                                    }}
                                    expanded={this.state.open}
                                    hidden={
                                        !userActions.checkSubscriptionPage(
                                            subs,
                                            7,
                                        )
                                    }
                                >
                                    <AccordionSummary
                                        classes={{
                                            root: classes.accordionSummaryRoot,
                                            content:
                                                classes.accordionSummaryContent,
                                        }}
                                        onClick={() => {
                                            if (!this.state.open) {
                                                this.setState({
                                                    open: true,
                                                })
                                            }
                                        }}
                                    >
                                        <SettingsIcon
                                            className={classes.iconMenu}
                                            style={{ height: 32, width: 32 }}
                                        />
                                        <ListItemText
                                            title={this.displayText('settings')}
                                            primary={this.displayText(
                                                'settings',
                                            )}
                                            disableTypography
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails
                                        classes={{
                                            root: classes.accordionDetailsRoot,
                                        }}
                                    >
                                        {/* MyUsers */}
                                        <BoatOnBlock
                                            permissions={{
                                                action: Actions.Read,
                                                entityName:
                                                    PermissionsEntities.LinkRoleGroupUser,
                                            }}
                                            children={
                                                <>
                                                    <ListItem
                                                        button
                                                        title={this.displayText(
                                                            `cta`,
                                                        )}
                                                        link={this.displayText(
                                                            `captain`,
                                                        )}
                                                        redirect={redirectPro}
                                                        component={
                                                            userActions.checkSubscriptionPage(
                                                                subs,
                                                                7,
                                                            )
                                                                ? LocalizedLink
                                                                : ToolTipCTA
                                                        }
                                                        to={
                                                            AppRoute.LogBook
                                                                .Settings
                                                                .MyUsers
                                                        }
                                                        disabled={
                                                            !userActions.checkSubscriptionPage(
                                                                subs,
                                                                7,
                                                            )
                                                        }
                                                        className={
                                                            classes.subMenuItem
                                                        }
                                                    >
                                                        <ListItemText
                                                            title={this.displayText(
                                                                'myUsers',
                                                            )}
                                                            primary={this.displayText(
                                                                'myUsers',
                                                            )}
                                                            secondary={
                                                                NEW_CONTENT.includes(
                                                                    'MyUsers',
                                                                ) && newBadge
                                                            }
                                                            disableTypography
                                                            onClick={
                                                                this.changePage
                                                            }
                                                        />
                                                    </ListItem>
                                                </>
                                            }
                                        />
                                        {/* WorkTimes */}
                                        <BoatOnBlock
                                            permissions={{
                                                action: Actions.Read,
                                                entityName:
                                                    PermissionsEntities.WorkTimeSetting,
                                            }}
                                            children={
                                                <>
                                                    <ListItem
                                                        button
                                                        title={this.displayText(
                                                            `cta`,
                                                        )}
                                                        link={this.displayText(
                                                            `amiral`,
                                                        )}
                                                        redirect={redirectPro}
                                                        component={
                                                            userActions.checkSubscriptionPage(
                                                                subs,
                                                                13,
                                                            )
                                                                ? LocalizedLink
                                                                : ToolTipCTA
                                                        }
                                                        to={
                                                            AppRoute.LogBook
                                                                .Settings
                                                                .WorkTimes
                                                        }
                                                        disabled={
                                                            !userActions.checkSubscriptionPage(
                                                                subs,
                                                                13,
                                                            )
                                                        }
                                                        className={
                                                            classes.subMenuItem
                                                        }
                                                    >
                                                        <ListItemText
                                                            title={this.displayText(
                                                                'workTime',
                                                            )}
                                                            primary={this.displayText(
                                                                'workTime',
                                                            )}
                                                            secondary={
                                                                NEW_CONTENT.includes(
                                                                    'WorkTimes',
                                                                ) && newBadge
                                                            }
                                                            disableTypography
                                                            onClick={
                                                                this.changePage
                                                            }
                                                        />
                                                    </ListItem>
                                                </>
                                            }
                                        />

                                        {/* PaidVacations */}
                                        <BoatOnBlock
                                            permissions={{
                                                action: Actions.Read,
                                                entityName:
                                                    PermissionsEntities.PaidVacationSetting,
                                            }}
                                            children={
                                                <>
                                                    <ListItem
                                                        button
                                                        title={this.displayText(
                                                            `cta`,
                                                        )}
                                                        link={this.displayText(
                                                            `amiral`,
                                                        )}
                                                        redirect={redirectPro}
                                                        component={
                                                            userActions.checkSubscriptionPage(
                                                                subs,
                                                                14,
                                                            )
                                                                ? LocalizedLink
                                                                : ToolTipCTA
                                                        }
                                                        to={
                                                            AppRoute.LogBook
                                                                .Settings
                                                                .PaidVacations
                                                        }
                                                        disabled={
                                                            !userActions.checkSubscriptionPage(
                                                                subs,
                                                                14,
                                                            )
                                                        }
                                                        className={
                                                            classes.subMenuItem
                                                        }
                                                    >
                                                        <ListItemText
                                                            title={this.displayText(
                                                                'paidLeaves',
                                                            )}
                                                            primary={this.displayText(
                                                                'paidLeaves',
                                                            )}
                                                            secondary={
                                                                NEW_CONTENT.includes(
                                                                    'PaidVacations',
                                                                ) && newBadge
                                                            }
                                                            disableTypography
                                                            onClick={
                                                                this.changePage
                                                            }
                                                        />
                                                    </ListItem>
                                                </>
                                            }
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            }
                        />
                    }
                </List>
            </div>
        )
    }

    shouldRenderBoatSelecter() {
        const { pathname } = this.props
        const isMobile = window.innerWidth < 960

        if (isMobile) {
            return false
        }

        return (
            !this.isSamePath(pathname, AppRoute.LogBook.Fleet) &&
            !this.isSamePath(pathname, AppRoute.LogBook.OrderForm) &&
            !this.isSamePath(pathname, AppRoute.LogBook.Settings.MyUsers) &&
            !this.isSamePath(pathname, AppRoute.LogBook.Settings.WorkTimes) &&
            !this.isSamePath(
                pathname,
                AppRoute.LogBook.Settings.PaidVacations,
            ) &&
            !this.isSamePath(pathname, AppRoute.LogBook.LogBook) &&
            !this.isSamePath(pathname, AppRoute.LogBook.LogBookID) &&
            !this.isSamePath(pathname, AppRoute.LogBook.Orders)
        )
    }

    getCurrentPageName() {
        const pathToFind = history.location.pathname.replace(/\/$/, '')

        for (let page in AppRoute.LogBook) {
            let path = AppRoute.LogBook[page]

            if (
                typeof path === 'object' &&
                !Array.isArray(path) &&
                path !== null
            ) {
                for (let subpage in AppRoute.LogBook[page]) {
                    let subpath = AppRoute.LogBook[page][subpage]
                    if (this.getLogBookPath(subpath) === pathToFind)
                        return subpage
                }
            } else {
                if (this.getLogBookPath(path) === pathToFind) return page
            }
        }

        return null
    }

    getCurrentPageInfos() {
        const currentPage = this.getCurrentPageName()

        if (!currentPage) {
            console.info("Erreur : Impossible d'identifier la page actuelle !")
            return { id: -1 }
        }

        const currentPageInfos =
            DICT_PAGES_IDS.find(item => item.name === currentPage) ?? null
        if (currentPageInfos === null) {
            console.info(
                "Erreur : Impossible d'identifier l'id de la page actuelle !",
            )
            return { id: -1 }
        }

        return currentPageInfos
    }

    render() {
        const {
            classes,
            subscriptions,
            loadingUser,
            pathname,
            events,
            boatsLoading,
            numberBoats,
        } = this.props

        if (
            (!subscriptions && loadingUser > 0) ||
            (events === null && numberBoats === undefined && boatsLoading !== 0)
        ) {
            return this.renderLoading('100px')
        }

        return (
            <div className={classes.root}>
                <div
                    className={
                        numberBoats > 0 &&
                        pathname !==
                            this.getLogBookPath(AppRoute.LogBook.Fleet) &&
                        pathname !==
                            this.getLogBookPath(
                                AppRoute.LogBook.Settings.MyUsers,
                            ) &&
                        pathname !==
                            this.getLogBookPath(AppRoute.LogBook.OrderForm) &&
                        pathname !==
                            this.getLogBookPath(AppRoute.LogBook.Calendar) &&
                        pathname !==
                            this.getLogBookPath(
                                AppRoute.LogBook.Settings.WorkTimes,
                            ) &&
                        pathname !==
                            this.getLogBookPath(
                                AppRoute.LogBook.Settings.PaidVacations,
                            )
                            ? classes.contentBoat
                            : classes.content
                    }
                >
                    <SideNavigation
                        list={this.menu(subscriptions)}
                        main={this.getMain(subscriptions)}
                        open={this.state.open}
                        handleClick={this.handleClick}
                        renderBoatSelecter={this.shouldRenderBoatSelecter()}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        subscriptions: state.group?.groupsMembers?.user?.sub || null,
        eventTypes: state.types.eventTypes,
        currentGroupId: state.group.currentGroupId,
        groupLoading: state.group.loading,
        bobLoading: state.bob.loading,
        loadingUser: state.authentication.loading,
        events: state.bob.events,
        boatsLoading: state.boat.loading,
        selectedGroup: state.block.selectedGroup,
        numberBoats: state.boat.numberBoats,
        redirectEvent: state.redirect.event,
        redirectParams: state.redirect.params,
        groupsMembers: state.group?.groupsMembers,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(LogbookNavigation))
