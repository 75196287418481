import React from 'react'
import { connect } from 'react-redux'

import BoatOnComponent from '../../../../common/BoatOnComponent'
import BoatOnLoading from '../../../../common/UsefullComponents/BoatOnLoading'
import { getContextFromUrl } from '../../../../../languages/LocalizerUtils'
import { MapContainer, Marker, TileLayer, Polyline } from 'react-leaflet'
import L from 'leaflet'

import startIcon from '../../../../../images/logbook/icons/map_pin_start.svg'
import stopOverIcon from '../../../../../images/logbook/icons/map_pin_stop.svg'
import endIcon from '../../../../../images/logbook/icons/map_pin_end.svg'
import arrow from '../../../../../../src/images/nav_south.svg'
import wind from '../../../../../../src/images/wind.svg'
import seaLevel from '../../../../../../src/images/seaLevel.svg'
import locationWeather from '../../../../../../src/images/location_weather.svg'

import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import 'chartjs-adapter-date-fns'

import dictionary from './Dictionary/DetailsTabDico'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/DetailsTabCss'
import {
    colorUtils,
    stepUtils,
    weatherUtils,
    haversineDistance,
    getDistanceFromCoordinates,
} from './Utils'
import { getSimpleFormatedTimeUTC } from '../../../../common/Utils'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LineController,
    TimeScale,
    Filler,
)

class DetailsTab extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            indexSpeedData: 0,
            selectedPoint: null,
            closestPoint: null,
            grantWeatherAccess: false,
            tempLimits: [0, 0],
            windLimits: [0, 0],
            seaLevelLimits: [0, 0],
        }

        this.speedChartRef = React.createRef()
        this.startIconL = new L.Icon({
            iconUrl: startIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [-3, -76],
        })
        this.stopOverIconL = new L.Icon({
            iconUrl: stopOverIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [-3, -76],
        })
        this.endIconL = new L.Icon({
            iconUrl: endIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [-3, -76],
        })

        this.getNavigationDuration = this.getNavigationDuration.bind(this)
        this.selectSpeed = this.selectSpeed.bind(this)
        this.getMapData = this.getMapData.bind(this)
        this.getNavigationData = this.getNavigationData.bind(this)
        this.getNavigationDistance = this.getNavigationDistance.bind(this)
        this.getMaxSpeed = this.getMaxSpeed.bind(this)
        this.getSpeedAverage = this.getSpeedAverage.bind(this)
        this.onClickLine = this.onClickLine.bind(this)
        this.getSelectedPointIcon = this.getSelectedPointIcon.bind(this)
        this.getConsumptionByType = this.getConsumptionByType.bind(this)
    }

    async componentDidMount() {
        await this._grantWeatherAccess()
    }

    async componentDidUpdate(prevProps) {
        const { navigation, weatherArray } = this.props
        if (
            prevProps.navigation !== navigation ||
            prevProps.weatherArray !== weatherArray
        ) {
            await this._grantWeatherAccess()
        }
    }

    getNavigationDuration() {
        const { navigation } = this.props

        let totalMinutes = 0
        if (!navigation.delimitedDate?.startDate) return '00h00'

        let start = new Date(navigation.delimitedDate?.startDate)
        let end = null

        navigation.stopOvers.forEach(stop => {
            end = new Date(stop.delimitedDate?.startDate)

            if (start) {
                totalMinutes += (end.getTime() - start.getTime()) / 1000 / 60

                start = null
                end = null
            }

            if (stop.delimitedDate?.endDate)
                start = new Date(stop.delimitedDate?.endDate)
            else start = new Date(stop.delimitedDate?.startDate)
        })

        if (start && navigation.delimitedDate?.endDate) {
            end = new Date(navigation.delimitedDate?.endDate)
            totalMinutes += (end.getTime() - start.getTime()) / 1000 / 60
        }

        const h = Math.trunc(totalMinutes / 60)
        const min = Math.trunc(totalMinutes % 60)
        return `${h < 10 ? '0' : ''}${h}h${min < 10 ? '0' : ''}${min}`
    }

    getNavigationDistance() {
        const { aisData, navigation } = this.props

        if (navigation.nbDepartingPassengers === null) return 0

        const selectedAis = []

        if (navigation.departureAddress)
            selectedAis.push([
                navigation.departureAddress.latitude,
                navigation.departureAddress.longitude,
            ])

        aisData.forEach(data =>
            selectedAis.push([data.latitude, data.longitude]),
        )

        const kmDistance = getDistanceFromCoordinates(selectedAis)

        return Math.round(kmDistance * 0.539957 * 100) / 100 //convert km to miles
    }

    getMaxSpeed() {
        const { aisData, navigation } = this.props

        if (navigation.nbDepartingPassengers === null) return 0

        let maxSpeed = 0

        aisData.forEach(data => {
            if (parseFloat(data.speed) > maxSpeed)
                maxSpeed = parseFloat(data.speed)
        })

        return maxSpeed
    }

    getSpeedAverage() {
        const { aisData, navigation } = this.props

        if (
            !aisData ||
            aisData?.length <= 0 ||
            navigation.nbDepartingPassengers === null
        )
            return 0

        const selectedAis = aisData.map(data => parseFloat(data.speed))

        const speedAverage =
            selectedAis.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0,
            ) / selectedAis.length

        return Math.round(speedAverage * 100) / 100
    }

    getConsumptionByType(id) {
        const { navigation, events } = this.props

        const currentEvents = events?.filter(
            event =>
                event?.delimitedDate?.startDate &&
                !event?.detail?.archived &&
                event.detail?.equipment?.equipmentFamily?.type?.id === id,
        )

        let currentEventsFormatted = currentEvents.map(event => ({
            id: event.detail?.equipment?.id,
            capacity: event.detail?.optionnalDetail?.capacity,
            fillingLevels: {
                atStart:
                    navigation.fillingLevelsAtStart.find(
                        item =>
                            item.equipment.id === event.detail?.equipment?.id,
                    )?.level ?? null,
                inAction:
                    navigation.fillingLevels.find(
                        item =>
                            item.equipment.id === event.detail?.equipment?.id,
                    )?.level ?? null,
            },
        }))

        currentEventsFormatted = currentEventsFormatted?.map(event => ({
            ...event,
            fillingLevels: {
                ...event.fillingLevels,
                quantityAtStart:
                    event.fillingLevels.atStart && event.capacity
                        ? event.capacity * (event.fillingLevels.atStart / 100)
                        : null,
                quantityInAction:
                    event.fillingLevels.inAction && event.capacity
                        ? event.capacity * (event.fillingLevels.inAction / 100)
                        : null,
            },
        }))

        const total = {
            atStart: currentEventsFormatted?.reduce((accumulator, item) => {
                if (
                    accumulator === null ||
                    item.fillingLevels.quantityAtStart === null
                )
                    return null
                return accumulator + item.fillingLevels.quantityAtStart
            }, 0),
            current: currentEventsFormatted?.reduce((accumulator, item) => {
                if (
                    accumulator === null ||
                    item.fillingLevels.quantity === null
                )
                    return null
                return accumulator + item.fillingLevels.quantityInAction
            }, 0),
        }

        return total?.atStart && total?.current
            ? total.atStart - total.current
            : 0
    }

    getNavigationData() {
        return [
            {
                id: 'durationTime',
                title: this.displayText('navigationTime'),
                data: this.getNavigationDuration(),
            },
            {
                id: 'distance',
                title: this.displayText('travelDistance'),
                data: this.getNavigationDistance(),
                unit: this.displayText('nauticMille'),
            },
            {
                id: 'speedMax',
                title: this.displayText('speedMax'),
                data: this.getMaxSpeed(),
                unit: 'kn',
            },
            {
                id: 'speedAverage',
                title: this.displayText('speedAverage'),
                data: this.getSpeedAverage(),
                unit: 'kn',
            },
            {
                id: 'consumption',
                title: this.displayText('consumption'),
                data: this.getConsumptionByType(2),
                unit: 'kwh',
            },
            {
                id: 'consumption',
                title: this.displayText('consumption'),
                data: this.getConsumptionByType(13),
                unit: 'L',
            },
        ]
    }

    _renderDataTravel() {
        const { classes, isMobile, navigation } = this.props

        if (!isMobile) return null

        const aisOnlyData = ['distance', 'speedMax', 'speedAverage']
        let data = this.getNavigationData()

        if (!navigation.isAisUsed)
            data = data.filter(data => !aisOnlyData.includes(data.id))

        return (
            <div className={classes.dataContainer}>
                {data.map((item, index) => {
                    if (item.id === 'consumption' && item.data === 0)
                        return null

                    return (
                        <div
                            className={classes.dataItem}
                            key={`dataItem-${index}`}
                        >
                            <div className={classes.dataValue}>
                                {item.data}
                                {item.unit && (
                                    <span className={classes.dataUnit}>
                                        &nbsp;{item.unit}
                                    </span>
                                )}
                            </div>
                            <div className={classes.dataTitle}>
                                {item.title}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    getMapData() {
        const { navigation, aisData } = this.props

        const mapData = {
            points: [],
            minPoint: {
                lat: 0,
                lon: 0,
            },
            maxPoint: {
                lat: 0,
                lon: 0,
            },
            center: {
                lat: 0,
                lon: 0,
            },
        }

        if (
            navigation?.departureAddress?.latitude &&
            navigation.departureAddress?.longitude
        ) {
            mapData.points.push({
                lat: navigation.departureAddress?.latitude,
                lon: navigation.departureAddress?.longitude,
            })
            mapData.maxPoint = {
                lat: navigation.departureAddress?.latitude,
                lon: navigation.departureAddress?.longitude,
            }
            mapData.minPoint = {
                lat: navigation.departureAddress?.latitude,
                lon: navigation.departureAddress?.longitude,
            }
        }

        if (navigation.stopOvers)
            navigation.stopOvers.forEach(stop => {
                if (stop?.address) {
                    mapData.points.push({
                        lat: stop.address?.latitude,
                        lon: stop.address?.longitude,
                    })
                    if (
                        stop.address?.latitude &&
                        stop.address?.latitude < mapData.minPoint.lat
                    ) {
                        mapData.minPoint.lat = stop.address?.latitude
                    }
                    if (
                        stop.address?.longitude &&
                        stop.address?.longitude < mapData.minPoint.lon
                    ) {
                        mapData.minPoint.lon = stop.address?.longitude
                    }

                    if (
                        stop.address?.latitude &&
                        stop.address?.latitude > mapData.maxPoint.lat
                    ) {
                        mapData.maxPoint.lat = stop.address?.latitude
                    }
                    if (
                        stop.address?.longitude &&
                        stop.address?.longitude > mapData.maxPoint.lon
                    ) {
                        mapData.maxPoint.lon = stop.address?.longitude
                    }
                }
            })

        if (
            navigation?.arrivalAddress?.latitude &&
            navigation.arrivalAddress?.longitude
        ) {
            mapData.points.push({
                lat: navigation.arrivalAddress?.latitude,
                lon: navigation.arrivalAddress?.longitude,
            })

            if (
                navigation.arrivalAddress?.latitude &&
                navigation.arrivalAddress?.latitude < mapData.minPoint.lat
            ) {
                mapData.minPoint.lat = navigation.arrivalAddress?.latitude
            }
            if (
                navigation.arrivalAddress?.longitude &&
                navigation.arrivalAddress?.longitude < mapData.minPoint.lon
            ) {
                mapData.minPoint.lon = navigation.arrivalAddress?.longitude
            }

            if (
                navigation.arrivalAddress?.latitude &&
                navigation.arrivalAddress?.latitude > mapData.maxPoint.lat
            ) {
                mapData.maxPoint.lat = navigation.arrivalAddress?.latitude
            }
            if (
                navigation.arrivalAddress?.longitude &&
                navigation.arrivalAddress?.longitude > mapData.maxPoint.lon
            ) {
                mapData.maxPoint.lon = navigation.arrivalAddress?.longitude
            }
        }

        mapData.center = {
            lon: mapData.maxPoint.lon - mapData.minPoint.lon,
            lat: mapData.maxPoint.lat - mapData.minPoint.lat,
        }

        mapData.minPoint = {
            lon: mapData.minPoint.lon - 0.05,
            lat: mapData.minPoint.lat - 0.05,
        }

        mapData.maxPoint = {
            lon:
                typeof mapData.maxPoint.lon === 'string'
                    ? parseFloat(mapData.maxPoint.lon) + 0.05
                    : mapData.maxPoint.lon + 0.05,
            lat:
                typeof mapData.maxPoint.lat === 'string'
                    ? parseFloat(mapData.maxPoint.lat) + 0.05
                    : mapData.maxPoint.lat + 0.05,
        }

        if (navigation.isAisUsed) {
            const selectedAis = aisData.map(data => ({
                lon: data.longitude,
                lat: data.latitude,
            }))

            selectedAis.forEach(point => {
                if (point.lon > mapData.maxPoint.lon)
                    mapData.maxPoint.lon = point.lon
                if (point.lat > mapData.maxPoint.lat)
                    mapData.maxPoint.lat = point.lat
                if (point.lon < mapData.minPoint.lon)
                    mapData.minPoint.lon = point.lon
                if (point.lat < mapData.minPoint.lat)
                    mapData.minPoint.lat = point.lat
            })
        }

        return mapData
    }

    _getAisData() {
        const { aisData, navigation } = this.props

        const aisPoints = []

        if (navigation.departureAddress)
            aisPoints.push([
                navigation.departureAddress.latitude,
                navigation.departureAddress.longitude,
            ])

        aisData
            .sort(
                (a, b) =>
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime(),
            )
            .forEach((data, index, array) => {
                aisPoints.push([data.latitude, data.longitude])
                const nextData =
                    index < array.length - 1 ? array[index + 1] : null

                const stopOvers =
                    !nextData && navigation?.stopOvers?.length > 0
                        ? navigation.stopOvers.filter(
                              (stop, index, stopOvers) =>
                                  index + 1 === stopOvers.length &&
                                  stop.delimitedDate.startDate &&
                                  new Date(stop.delimitedDate.startDate) >
                                      new Date(data.createdAt),
                          )
                        : navigation?.stopOvers?.length > 0
                        ? navigation.stopOvers.filter(
                              stop =>
                                  stop.delimitedDate.startDate &&
                                  new Date(nextData.createdAt) >
                                      new Date(stop.delimitedDate.startDate) &&
                                  new Date(stop.delimitedDate.startDate) >
                                      new Date(data.createdAt),
                          )
                        : null

                if (stopOvers && stopOvers.length > 0) {
                    stopOvers.forEach(stop => {
                        aisPoints.push([
                            stop.address.latitude,
                            stop.address.longitude,
                        ])
                    })
                }
            })

        if (navigation.arrivalAddress)
            aisPoints.push([
                navigation.arrivalAddress.latitude,
                navigation.arrivalAddress.longitude,
            ])

        return aisPoints
    }

    getClosestPoint(target, points) {
        let closestPoint = null
        let minDistance = null

        points.forEach((point, index) => {
            const distance = haversineDistance(
                [target.lat, target.lng],
                [point.latitude, point.longitude],
            )

            if (distance < minDistance || minDistance === null) {
                minDistance = distance
                closestPoint = point
            }
        })

        return closestPoint
    }

    onClickLine(line) {
        const { aisData } = this.props

        const closestPoint = this.getClosestPoint(line.latlng, aisData)
        const indexAis = aisData.findIndex(
            item => item.createdAt === closestPoint.createdAt,
        )

        this.setState(
            {
                selectedPoint: line.latlng,
                closestPoint: closestPoint,
                indexSpeedData: indexAis,
            },
            () => this.selectSpeed(indexAis),
        )
    }

    getSelectedPointIcon() {
        const { classes } = this.props
        const { closestPoint } = this.state

        const date = new Date(closestPoint.createdAt)
        const hours =
            date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
        const mins =
            date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
        const selectedPointIconL = new L.DivIcon({
            className: classes.selectedPoint,
            html: `
            <div>
                <div class=${classes.selectedPointFirstText}>
                    ${hours}:${mins}
                </div>
                <div class=${classes.selectedPointSecondText}>
                    ${closestPoint.speed} kn
                </div>
            </div>
            `,
        })

        return selectedPointIconL
    }

    _renderMap() {
        const { classes, navigation, isMobile } = this.props
        const { selectedPoint, closestPoint } = this.state
        const mapData = this.getMapData()
        const markers = mapData.points.map((point, index) => {
            if (index === 0) {
                return (
                    <Marker
                        position={point}
                        key={`marker-${index}`}
                        icon={this.startIconL}
                    />
                )
            } else if (
                index < mapData.points.length - 1 ||
                !navigation.delimitedDate.endDate
            ) {
                return (
                    <Marker
                        position={point}
                        key={`marker-${index}`}
                        icon={this.stopOverIconL}
                    />
                )
            } else {
                return (
                    <Marker
                        position={point}
                        key={`marker-${index}`}
                        icon={this.endIconL}
                    />
                )
            }
        })
        const aisOnlyData = ['distance', 'speedMax', 'speedAverage']

        if (selectedPoint && closestPoint) {
            markers.push(
                <Marker
                    position={[selectedPoint.lat, selectedPoint.lng]}
                    key={'maker-selected-point'}
                    icon={this.getSelectedPointIcon()}
                />,
            )
        }

        let data = this.getNavigationData()
        let aisPoints = []

        if (!navigation.isAisUsed)
            data = data.filter(data => !aisOnlyData.includes(data.id))

        if (navigation.isAisUsed) {
            aisPoints = this._getAisData()
        }

        return (
            <div className={classes.mapContainer}>
                <MapContainer
                    center={mapData.center}
                    bounds={[
                        [mapData.minPoint.lat, mapData.minPoint.lon],
                        [mapData.maxPoint.lat, mapData.maxPoint.lon],
                    ]}
                    maxZoom={13}
                    minZoom={2}
                >
                    <TileLayer
                        attribution={`&copy;
                            <a href="https://www.openstreetmap.org/copyright">
                                OpenStreetMap
                            </a> contributors`}
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {navigation.nbDepartingPassengers !== null && (
                        <Polyline
                            pathOptions={{
                                color: '#303F9F',
                                weight: 2,
                                smoothFactor: 0.5,
                            }}
                            positions={aisPoints}
                            eventHandlers={{
                                click: this.onClickLine,
                                onMouseOver: this.onClickLine,
                            }}
                        />
                    )}
                    {markers}
                </MapContainer>
                {!isMobile && (
                    <div className={classes.dataInMapContainer}>
                        {data.map((item, index) => {
                            if (item.id === 'consumption' && item.data === 0)
                                return null
                            return (
                                <div
                                    className={classes.dataItem}
                                    key={`dataItem-${index}`}
                                >
                                    <div className={classes.dataValueInMap}>
                                        {item.data}
                                        {item.unit && (
                                            <span className={classes.dataUnit}>
                                                &nbsp;{item.unit}
                                            </span>
                                        )}
                                    </div>
                                    <div className={classes.dataTitleInMap}>
                                        {item.title}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        )
    }

    updateIndexSpeedData(index) {
        const { aisData } = this.props

        if (index !== this.state.indexSpeedData) {
            this.setState(
                {
                    indexSpeedData: index,
                    selectedPoint: {
                        lat: aisData[index].latitude,
                        lng: aisData[index].longitude,
                    },
                    closestPoint: aisData[index],
                },
                () => this.selectSpeed(index),
            )
        }
    }

    getChartPlugin() {
        return [
            {
                afterDraw: chart => {
                    if (
                        chart?.tooltip?._active &&
                        chart?.tooltip?._active?.length
                    ) {
                        const activePoint = chart.tooltip._active[0]
                        const ctx = chart.ctx
                        const x = activePoint.element.x
                        const topY = chart.scales.y.top
                        const bottomY = chart.scales.y.bottom

                        this.updateIndexSpeedData(
                            chart.tooltip.dataPoints[0].dataIndex,
                        )

                        ctx.save()
                        ctx.beginPath()
                        ctx.moveTo(x, topY)
                        ctx.lineTo(x, bottomY)
                        ctx.lineWidth = 1
                        ctx.strokeStyle = '#000000'
                        ctx.stroke()
                        ctx.restore()
                    }
                },
            },
        ]
    }

    getChartOptions() {
        return {
            maintainAspectRatio: false,
            interaction: {
                intersect: false,
                mode: 'index',
            },
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                },
                tooltip: {
                    callbacks: {
                        label: _ => null,
                        title: context => {
                            const date = new Date(context[0].parsed.x)
                            const title = `${date.getHours()}:${
                                date.getMinutes() < 10 ? '0' : ''
                            }${date.getMinutes()}`

                            return title
                        },
                    },
                },
            },
            elements: {
                line: {
                    tension: 0.2,
                    fill: true,
                },
            },
            responsive: true,
            scales: {
                x: {
                    display: true,
                    type: 'time',
                    time: {
                        unit: 'hour',
                        displayFormats: {
                            hour: 'HH:mm',
                        },
                    },
                },
                y: {
                    beginAtZero: false,
                },
            },
            events: ['mousemove', 'touchmove'],
        }
    }

    selectSpeed(index) {
        const chart = this.speedChartRef.current

        if (!chart) return

        chart.setActiveElements([
            {
                datasetIndex: 0,
                index: index,
            },
        ])

        chart.tooltip.setActiveElements([
            {
                datasetIndex: 0,
                index: index,
            },
        ])

        chart.update()
    }

    _renderSpeedGraph() {
        const { classes, navigation, aisData } = this.props

        const speedArray = aisData.map(data => ({
            x: new Date(data.createdAt),
            y: parseFloat(data.speed),
        }))

        const data = {
            datasets: [
                {
                    fill: true,
                    label: 'speed',
                    data: speedArray,
                    borderWidth: 0,
                    backgroundColor: 'rgb(95, 167, 246)',
                },
            ],
        }

        if (!navigation.isAisUsed || navigation.nbDepartingPassengers === null)
            return null

        return (
            <div className={classes.speedGraphContainer}>
                <div className={classes.titleSection}>
                    {this.displayText('speed')}
                </div>
                <div>
                    <Line
                        ref={this.speedChartRef}
                        options={this.getChartOptions(speedArray)}
                        data={data}
                        plugins={this.getChartPlugin()}
                    />
                </div>
            </div>
        )
    }

    _getLimits(data, key) {
        const { weatherArray } = this.props

        if (!weatherArray) return 0

        let minValue = Infinity
        let maxValue = -Infinity

        for (let i = 0; i < data.length; i++) {
            const currentValue = parseFloat(data[i][key])
            if (currentValue > maxValue) {
                maxValue = currentValue
            }
            if (currentValue < minValue) {
                minValue = currentValue
            }
        }

        return [minValue, maxValue]
    }

    _formatShortDate(dateStr) {
        const date = new Date(dateStr)
        const options = { weekday: 'short', day: '2-digit', month: '2-digit' }
        return date.toLocaleDateString('fr-FR', options).replace(',', '')
    }

    _formatHoursFromDate(dateStr) {
        return new Date(dateStr).toLocaleTimeString('fr-FR', {
            timeStyle: 'short',
            hour12: false,
        })
    }

    _buildDayRow() {
        const { weatherArray } = this.props
        let previousDay = null

        return (
            <tr>
                <td></td>
                {weatherArray.map((w, index) => {
                    const date = new Date(w?.recordTime)
                    if (
                        !previousDay ||
                        date.getDate() !== previousDay.getDate()
                    ) {
                        previousDay = new Date(w?.recordTime)
                        return (
                            <td key={`date-${index}`}>
                                {this._formatShortDate(new Date(w?.recordTime))}
                            </td>
                        )
                    }
                    return <td key={`empty-${index}`}></td>
                })}
            </tr>
        )
    }

    _buildHourRow() {
        const { weatherArray, classes } = this.props

        return (
            <tr>
                <td></td>
                {weatherArray.map((w, index) => {
                    const hour = getSimpleFormatedTimeUTC(
                        new Date(w.recordTime),
                        this.context,
                    )
                    return (
                        <td
                            key={`hour-${index}`}
                            className={hour === '00:00' ? classes.isDay : ''}
                        >
                            {hour}
                        </td>
                    )
                })}
            </tr>
        )
    }

    _drawArrows(firstStepNbHours, secondStepNbHours) {
        const { classes } = this.props

        return (
            <>
                {firstStepNbHours > 0 && (
                    <div
                        className={classes.rightArrow}
                        style={{
                            width: `calc((100% + 8px)* ${firstStepNbHours} - 5px)`, // 5px to put some space between arrows
                        }}
                    />
                )}
                {secondStepNbHours > 0 && (
                    <div
                        className={classes.leftArrow}
                        style={{
                            width: `calc((100% + 8px) * ${secondStepNbHours} - 5px)`, // 5px to put some space between arrows
                            left: `calc((100% + 8px) * ${firstStepNbHours ||
                                0} + (100% + 8px))`,
                        }}
                    />
                )}
            </>
        )
    }

    _drawStopOverLine(nextStepNbHours, isStopOver) {
        const { classes } = this.props

        return (
            nextStepNbHours &&
            isStopOver && (
                <div
                    className={classes.stopOverLine}
                    style={{
                        width: `calc(76px * ${nextStepNbHours})`,
                    }}
                />
            )
        )
    }

    _buildLocationRow() {
        const { weatherArray } = this.props
        const steps = stepUtils.getNavigationSteps(this.props.navigation)
        let stepIndex = 0
        let countNbHoursDisplayedInStep = 0

        return (
            <tr>
                <td>{this.displayText('location')}</td>
                {weatherArray.map((w, index) => {
                    if (
                        steps?.[stepIndex]?.totalNbHours &&
                        countNbHoursDisplayedInStep >
                            steps[stepIndex].totalNbHours
                    ) {
                        countNbHoursDisplayedInStep = 0
                        stepIndex++
                    }

                    const location =
                        w?.address?.city ||
                        `${w?.address?.latitude}, ${w?.address?.longitude}`

                    if (location && countNbHoursDisplayedInStep === 0) {
                        countNbHoursDisplayedInStep++
                        return (
                            <td key={`location-${index}`}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {location}
                                    <img
                                        src={locationWeather}
                                        alt="location icon"
                                        height={15}
                                    />
                                    {index < weatherArray.length - 1 &&
                                        (!steps[stepIndex]?.isStopOver &&
                                        steps[stepIndex]?.firstStepNbHours
                                            ? this._drawArrows(
                                                  steps[stepIndex]
                                                      ?.firstStepNbHours,
                                                  steps[stepIndex]
                                                      ?.secondStepNbHours,
                                              )
                                            : this._drawStopOverLine(
                                                  steps[stepIndex]
                                                      ?.firstStepNbHours,
                                                  steps[stepIndex]?.isStopOver,
                                              ))}
                                </div>
                            </td>
                        )
                    }
                    countNbHoursDisplayedInStep++
                    return <td key={`empty-${index}`}></td>
                })}
            </tr>
        )
    }

    _buildWindDirRow() {
        const { weatherArray } = this.props

        return (
            <tr>
                <td>{this.displayText('windDirection')}</td>
                {weatherArray.flatMap((w, index) => (
                    <td key={`wind-dir-${index}`}>
                        {this._buildWaveCircle(w, 'windDirection')}
                    </td>
                ))}
            </tr>
        )
    }

    _buildWindSpeedRow() {
        const { weatherArray } = this.props

        return (
            <tr>
                <td>
                    {this.displayText('windSpeed')}
                    <span>kts</span>
                </td>
                {weatherArray.map((w, index) => (
                    <td
                        key={`wind-speed-${index}`}
                        style={{
                            backgroundColor: colorUtils.getColorWind(
                                this._convertMsToKts(parseFloat(w.windSpeed)),
                            ),
                        }}
                    >
                        {this._convertMsToKts(parseFloat(w.windSpeed))}
                    </td>
                ))}
            </tr>
        )
    }

    _buildGustRow() {
        const { weatherArray } = this.props

        return (
            <tr>
                <td>
                    {this.displayText('gust')}
                    <span>kts</span>
                </td>
                {weatherArray.map((w, index) => (
                    <td
                        key={`gust-${index}`}
                        style={{
                            backgroundColor: colorUtils.getColorWind(
                                parseFloat(w.gust),
                            ),
                        }}
                    >
                        {parseFloat(w.gust)}
                    </td>
                ))}
            </tr>
        )
    }

    _buildWaveHeightRow() {
        const { weatherArray } = this.props

        return (
            <tr>
                <td>
                    {this.displayText('waveHeight')}
                    <span>m</span>
                </td>
                {weatherArray.map((w, index) => (
                    <td
                        key={`wave-height-${index}`}
                        style={{
                            backgroundColor: colorUtils.getColorWaves(
                                w.waveHeight,
                            ),
                        }}
                    >
                        {parseFloat(w.waveHeight)}
                    </td>
                ))}
            </tr>
        )
    }

    _buildWavePeriodRow() {
        const { weatherArray } = this.props

        return (
            <tr>
                <td>
                    {this.displayText('wavePeriod')}
                    <span>s</span>
                </td>
                {weatherArray.map((w, index) => (
                    <td key={`wave-period-${index}`}>
                        {parseFloat(w.wavePeriod) || ''}
                    </td>
                ))}
            </tr>
        )
    }

    _buildWaveDirectionRow() {
        const { weatherArray } = this.props

        return (
            <tr>
                <td>{this.displayText('waveDirection')}</td>
                {weatherArray.flatMap((w, index) => (
                    <td key={`wave-dir-${index}`}>
                        {this._buildWaveCircle(w, 'waveDirection')}
                    </td>
                ))}
            </tr>
        )
    }

    _buildWaveCircle(weatherData, dataToDisplay) {
        const { classes } = this.props

        const direction = parseFloat(weatherData[dataToDisplay])

        if (parseFloat(weatherData['wavePeriod']) === 0 && direction === 0)
            return <></> // if direction is 0, we don't display anything if it's the wave direction because that mean there is not wave data

        return (
            <img
                src={arrow}
                style={{
                    transform: `rotate(${direction}deg)`,
                }}
                className={classes.boatOnWeatherTableAreaIcon}
                alt="direction icon"
            />
        )
    }

    _buildSwellHeightRow() {
        const { weatherArray } = this.props

        return (
            <tr>
                <td>
                    {this.displayText('swellHeight')}
                    <span>m</span>
                </td>
                {weatherArray.flatMap((w, index) => (
                    <td
                        key={`swell-height-${index}`}
                        style={{
                            backgroundColor: colorUtils.getColorWaves(
                                w.swellHeight,
                            ),
                        }}
                    >
                        {w.swellHeight}
                    </td>
                ))}
            </tr>
        )
    }

    _buildAirTemperatureRow() {
        const { weatherArray } = this.props
        const rows = weatherArray.flatMap((w, index) => (
            <td
                key={index}
                style={{
                    backgroundColor: colorUtils.getColorTemperature(
                        w.airTemperature,
                    ),
                }}
            >
                {w.airTemperature}
            </td>
        ))

        return (
            <tr>
                <td>
                    {this.displayText('airTemperature')}
                    <span>°C</span>
                </td>
                {rows}
            </tr>
        )
    }

    _buildWaterTemperatureRow() {
        const { weatherArray } = this.props
        const rows = weatherArray.flatMap((w, index) => (
            <td
                key={index}
                style={{
                    backgroundColor: colorUtils.getColorTemperature(
                        w.waterTemperature,
                    ),
                }}
            >
                {w.waterTemperature}
            </td>
        ))

        return (
            <tr>
                <td>
                    {this.displayText('waterTemperature')}
                    <span>°C</span>
                </td>
                {rows}
            </tr>
        )
    }

    _buildCloudCoverRow() {
        const { weatherArray } = this.props
        const rows = weatherArray.flatMap((w, index) => (
            <td key={index}>{w.cloudCover}</td>
        ))

        return (
            <tr>
                <td>
                    {this.displayText('cloudCover')}
                    <span>%</span>
                </td>
                {rows}
            </tr>
        )
    }

    _buildVisibilityRow() {
        const { weatherArray } = this.props
        const rows = weatherArray.flatMap((w, index) => (
            <td
                key={index}
                style={{
                    backgroundColor: colorUtils.getColorVisibility(
                        w.visibility,
                    ),
                }}
            >
                {w.visibility}
            </td>
        ))

        return (
            <tr>
                <td>
                    {this.displayText('visibility')}
                    <span>km</span>
                </td>
                {rows}
            </tr>
        )
    }

    _buildPrecipitationRow() {
        const { weatherArray } = this.props
        const rows = weatherArray.flatMap((w, index) => (
            <td
                key={index}
                style={{
                    backgroundColor: colorUtils.getColorPrecipitation(
                        w.precipitation,
                    ),
                }}
            >
                {parseFloat(w.precipitation) || ''}
            </td>
        ))

        return (
            <tr>
                <td>
                    {this.displayText('precipitation')}
                    <span>mm/h</span>
                </td>
                {rows}
            </tr>
        )
    }

    _buildSeaLevelRow() {
        const { weatherArray } = this.props
        const seaLevels = weatherArray.flatMap((w, index) => (
            <td
                style={{
                    backgroundColor: 'white',
                }}
            >
                {parseFloat(w.seaLevel) || ''}
            </td>
        ))

        return (
            <tr>
                <td>
                    {this.displayText('seaLevel')}
                    <span>m</span>
                </td>
                {seaLevels}
            </tr>
        )
    }

    _convertMsToKts(speedMs) {
        const kts = speedMs * 1.94384
        return kts.toFixed(2)
    }

    _selectWeatherImage() {
        const { classes, weatherArray } = this.props

        if (!weatherArray?.[0]) return <></>

        const selectedWeather = weatherUtils.getGlobalNavigationWeather(
            weatherArray,
        )

        return (
            <img
                src={
                    selectedWeather.length > 0
                        ? selectedWeather[0]
                        : selectedWeather
                }
                className={classes.boatOnWeatherCardAreaCardBodyIconImg}
                alt="icon"
            />
        )
    }

    _renderWeatherOverview() {
        const { classes } = this.props
        const { tempLimits, windLimits, seaLevelLimits } = this.state

        return (
            <>
                <h1>{this.displayText('weatherOverview')}</h1>
                <div className={classes.boatOnWeatherCardArea}>
                    <div className={classes.boatOnWeatherCardAreaCard}>
                        <div
                            className={classes.boatOnWeatherCardAreaCardHeader}
                        >
                            <h2>{this.displayText('weather')}</h2>
                            <p>max {tempLimits[1]}°</p>
                            <span>min {tempLimits[0]}°</span>
                        </div>
                        <div className={classes.boatOnWeatherCardAreaCardBody}>
                            <div
                                className={
                                    classes.boatOnWeatherCardAreaCardBodyIcon
                                }
                            >
                                {this._selectWeatherImage()}
                            </div>
                        </div>
                    </div>
                    <div className={classes.boatOnWeatherCardAreaCard}>
                        <div
                            className={classes.boatOnWeatherCardAreaCardHeader}
                        >
                            <h2>{this.displayText('wind')}</h2>
                            <p>max {windLimits[1]}m/h</p>
                            <span>min {windLimits[0]}m/h</span>
                        </div>
                        <div className={classes.boatOnWeatherCardAreaCardBody}>
                            <div
                                className={
                                    classes.boatOnWeatherCardAreaCardBodyIcon
                                }
                            >
                                <img
                                    src={wind}
                                    className={
                                        classes.boatOnWeatherCardAreaCardBodyIconImg
                                    }
                                    alt="icon"
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.boatOnWeatherCardAreaCard}>
                        <div
                            className={classes.boatOnWeatherCardAreaCardHeader}
                        >
                            <h2>{this.displayText('seaLevel')}</h2>
                            <p>max {seaLevelLimits[1]}m</p>
                            <span>min {seaLevelLimits[0]}m</span>
                        </div>
                        <div className={classes.boatOnWeatherCardAreaCardBody}>
                            <div
                                className={
                                    classes.boatOnWeatherCardAreaCardBodyIcon
                                }
                            >
                                <img
                                    src={seaLevel}
                                    className={
                                        classes.boatOnWeatherCardAreaCardBodyIconImg
                                    }
                                    alt="icon"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    _renderWeather() {
        const { classes, weatherArray } = this.props
        const { tempLimits, windLimits, seaLevelLimits } = this.state

        if (!tempLimits && !windLimits && !seaLevelLimits) return null

        return (
            <div className={classes.boatOnWeather}>
                {this._renderWeatherOverview()}
                <div className={classes.boatOnWeatherTableArea}>
                    <table>
                        <tbody>
                            {weatherArray ? (
                                <>
                                    {this._buildDayRow()}
                                    {this._buildHourRow()}
                                    {this._buildLocationRow()}
                                    {this._buildWindDirRow()}
                                    {this._buildWindSpeedRow()}
                                    {this._buildGustRow()}
                                    {this._buildWaveHeightRow()}
                                    {this._buildWavePeriodRow()}
                                    {this._buildWaveDirectionRow()}
                                    {this._buildSwellHeightRow()}
                                    {this._buildAirTemperatureRow()}
                                    {this._buildWaterTemperatureRow()}
                                    {this._buildCloudCoverRow()}
                                    {this._buildVisibilityRow()}
                                    {this._buildPrecipitationRow()}
                                    {this._buildSeaLevelRow()}
                                </>
                            ) : null}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    async _grantWeatherAccess() {
        const { weatherArray } = this.props

        if (!weatherArray) return null

        if (!weatherArray || weatherArray.length === 0) return null

        this.setState({
            tempLimits: this._getLimits(weatherArray, 'airTemperature'),
            windLimits: this._getLimits(weatherArray, 'windSpeed'),
            seaLevelLimits: this._getLimits(weatherArray, 'seaLevel'),
            grantWeatherAccess: true,
        })
    }

    render() {
        const { classes, navigation } = this.props
        const { grantWeatherAccess } = this.state

        if (!navigation)
            return (
                <div className={classes.root}>
                    <BoatOnLoading />
                </div>
            )

        return (
            <div className={classes.root}>
                {this._renderDataTravel()}
                {this._renderMap()}
                {this._renderSpeedGraph()}
                {grantWeatherAccess && this._renderWeather()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const id = url.split('/').slice(-1)[0]
    const navigation = state.logbook?.navigations?.find(
        nav => nav.id === parseInt(id),
    )
    const context = getContextFromUrl(url)
    const isMobile = window.innerWidth < 600
    const groupBoats = state.group?.groupsMembers?.boats || []
    let currentBoat = null

    if (navigation) groupBoats.find(boat => boat.id === navigation.boatID)

    const currentAis = navigation
        ? (state.navigationApiData.ais || []).filter(
              ais => ais.navigationId === navigation.id,
          )[0] || null
        : null

    return {
        isMobile,
        context,
        linkedUsers: state.group?.groupsMembers?.linkRGU || [],
        navigation: navigation,
        currentBoat,
        aisData: currentAis?.aisData || [],
        events: state.bob.events,
        weatherArray: state.navigationApiData.weatherArray,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(DetailsTab))
