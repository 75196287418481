import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../BoatOnComponent'

import styles from '../../RentParkingPage/RentNavigationPages/Styles/CaracteristicsTabCss'
import dictionary from '../../RentParkingPage/RentNavigationPages/Dictionary/CaracteristicsTabDico'

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete'
import { InputAdornment, TextField } from '@material-ui/core'
import { Business as BusinessIcon } from '@mui/icons-material'

/**
 * Component to search the address
 *
 * @class AddressSearch
 * @extends {BoatOnComponent}
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {StyleObject} style - style to apply to parent div.
 * @param {String} placeholder - placeholder of the input.
 * @param {String} label - label of the input.
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 18/09/2024 - 09:34
 * @author Samuel.C
 */

class AddressSearch extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary

        this.state = {
            value: null,
            selected: null,
            address: '',
        }
    }

    componentDidMount() {
        const { fullAddressInfo } = this.props

        if (fullAddressInfo)
            this.setState({
                value: { fullText: fullAddressInfo },
                selected: fullAddressInfo,
            })
    }

    componentDidUpdate() {
        const { selected } = this.state
        const { fullAddressInfo } = this.props
        if ((fullAddressInfo || fullAddressInfo === '') && fullAddressInfo !== selected) {
            this.setState({
                value: { fullText: fullAddressInfo },
                selected: fullAddressInfo,
            })
        }
    }

    handleChange = address => {
        this.setState({ value: { fullText: address } })
    }

    handleSelect = address => {
        geocodeByAddress(address)
            .then(async results => {
                const coords = await getLatLng(results[0])
                const value = {
                    fullText: results[0].formatted_address,
                    latitude: coords.lat,
                    longitude: coords.lng,
                }
                this.select(value)
                this.setState({ value: value })
            })
            .catch(error => console.error('Error', error))
    }

    render() {
        const {
            classes,
            style,
            placeholder,
            label,
            InputProps,
            helperText,
            error,
            overrideClassName,
        } = this.props
        console.log(this.state.value?.fullText)
        return (
            <div
                className={`${classes.addressGroup} ${overrideClassName || ''}`}
                style={style}
            >
                <PlacesAutocomplete
                    value={
                        this.state.value?.fullText
                            ? this.state.value?.fullText
                            : ''
                    }
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                >
                    {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                    }) => (
                        <>
                            <TextField
                                label={label ? label : placeholder}
                                variant="outlined"
                                margin={'dense'}
                                {...getInputProps({
                                    placeholder: placeholder
                                        ? placeholder
                                        : label,
                                    className: classes.fieldAddress,
                                })}
                                error={error}
                                helperText={helperText}
                                InputProps={InputProps}
                                InputLabelProps={InputProps}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item'
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? {
                                              backgroundColor: '#fafafa',
                                              cursor: 'pointer',
                                          }
                                        : {
                                              backgroundColor: '#ffffff',
                                              cursor: 'pointer',
                                          }
                                    return (
                                        <div
                                            {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                    className,
                                                    style,
                                                },
                                            )}
                                        >
                                            <span>
                                                {suggestion.description}
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    )}
                </PlacesAutocomplete>
            </div>
        )
    }

    select(value) {
        this.setState({
            value: value,
            selected: value,
        })
        this.props.setAddress(value)
    }
}

/**
 * display input address field based on Textfield from MUI
 *
 * @class AddressSearch
 * @extends {BoatOnComponent}
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {Object} style - object of style.
 * @param {Boolean} notOutlined - if true, remove outline style.
 * @param {Boolean} fullWidth - if true, the field will take all space available.
 * @param {Boolean} fullWidth - if true, the field will take all space available.
 * @param {Boolean} labelHide - if true, hide the label.
 * @param {String} placeholder - placeholder of the input.
 * @param {String} label - label of the input.
 * @param {String} [margin='dense'] - type of the margin (cf MUI docs).
 * @param {Object} InputProps - check MUI documentation.
 * @param {Boolean} required - if true, add * on the label of the field.
 * @param {String} helperText - text to display on hover ? icon.
 * @param {Boolean} error - if true, put field in error style.
 * @param {Boolean} multiline - if true, authorize multiline.
 * @param {Boolean} disabled - if true, disable the input field.
 * @param {String} type - type of the search : ["city, address"] default address.
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 23/04/2024 - 17:01
 * @author Samuel.C
 */
export default withStyles(styles)(AddressSearch)
